tr:nth-child(odd) {background-color: #f2f2f2;}


.ant-table-thead .ant-table-cell {
  text-align: left;
  padding: 8px;
  border-radius: 0px;
}

.ant-table-thead {
  background-color: white;
  border-radius: 0px;

}

.ant-table-tbody::before {
  line-height:.5em;
    content:".";
    color:white; /* bacground color */
    display:block;
}

.ant-table-wrapper .ant-spin-container .ant-table{
  background-color: transparent;
  border-radius: 0px;
}

.ant-table-container .ant-table-content table .ant-table-thead tr th:first-child{
  border-start-start-radius: 0px;
  border-start-end-radius: 0px;
}

.ant-table-container .ant-table-content table .ant-table-thead tr th:last-child{
  border-start-start-radius: 0px;
  border-start-end-radius: 0px;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-1m62vyb).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
    position: unset;
    top: 50%;
    inset-inline-end: 0;
    height: 0;
    background-color: #f0f0f0;
    transform: translateY(-50%);
    transition: background-color 0.2s;
    content: "";
}

.custom-info-wallet-table .ant-table-tbody > tr > td {
  border: none;
}

.custom-waller-button {
  border: 1px solid #252525; /* Black border */
  color: #fff; /* Black text color */
  font-weight: 600;
  background-color: #252525; /* Transparent background */
  cursor: pointer; /* Changes the cursor to a pointer on hover */
  transition: all 0.2s ease, visibility 0s; 
}

.custom-waller-button:hover {
  color: #000; /* Black text color on hover */
  background-color: #fff; /* White background on hover */
  border-color: #000; /* Black border color on hover */
  font-weight: normal;
}