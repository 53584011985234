.dashboard-card-widget.widget-card {
  min-height: 271px;
}

.srm-notification-card.widget-card {
  min-height: 540px;
}

.cost-management-card.widget-card {
  min-height: 350px;
}
