.custom-text-sm {
  font-size: 23px;
  @media screen and (max-width: 800px) {
    font-size: 17px;
  }
  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
}

.styled-card {
  width: 50%;
  @media screen and (max-width: 1500px) {
    width: 70%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

.custom-button {
  padding: 7px 55px;
  float: right;
  background: #00973A;
  @media screen and (max-width: 1430px) {
    padding: 7px 40px;
  }
  @media screen and (max-width: 1000px) {
    padding: 7px 20px;
  }
  @media screen and (max-width: 560px) {
    padding: 1px 5px;
  }
}

.custom-button :hover {
  transition: all 0.2s ease, visibility 0s;
}

.custom-duration-btn {
  margin-right: 25px;
  padding-top: 5px;
  width: 68px;
  height: 31px;
  @media screen and (max-width: 560px) {
    margin-right: 10px;
  }
}

.custom-modal .ant-modal .ant-modal-content {
  border-radius: 0;
}

.ant-divider-horizontal {
  background-color: rgb(223 221 221);
}

/* CustomModal.css */

@media (max-width: 576px) {
    .custom-modal {
      width: 100%;
      min-width: 100%;
    }
  }
  
  @media (min-width: 576px) and (max-width: 768px) {
    .custom-modal {
      width: 100%;
      min-width: 100%;
    }
  }
  
  @media (min-width: 768px) and (max-width: 992px) {
    .custom-modal {
      width: 70%;
      min-width: 70%;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    .custom-modal {
      width: 60%;
      min-width: 60%;
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1600px) {
    .custom-modal {
      width: 50%;
      min-width: 50%;
    }
  }
  
  @media (min-width: 1600px) {
    .custom-modal {
      width: 43%;
      min-width: 43%;
    }
  }