.wallet-table tr:nth-child(odd) {
    background-color: white;
  }
  
  .wallet-table tr:nth-child(even) {
    background-color: #ededed;
  }
  
  .wallet-table .ant-table-tbody::before {
    line-height: 0.5em;
    content: none;
    color: white;
    display: block;
  }
  
  .wallet-table table .ant-table-thead tr th {
    background-color: #bdbdbd;
  }
  
  .wallet-table .ant-table-container
    .ant-table-content
    table
    .ant-table-thead
    tr
    th:first-child {
    border-start-start-radius: 4px;
    border-end-start-radius: 4px;
  }
  
  .wallet-table .ant-table-container
    .ant-table-content
    table
    .ant-table-thead
    tr
    th:last-child {
    border-end-end-radius: 4px;
    border-start-end-radius: 4px;
  }
  
  .wallet-table .ant-table-container .ant-table-content table .ant-table-tbody tr td:first-child{
    border-start-start-radius: 4px;
    border-end-start-radius: 4px;
  }
  
  .wallet-table .ant-table-container .ant-table-content table .ant-table-tbody tr td:last-child{
    border-end-end-radius: 4px;
    border-start-end-radius: 4px;
  }
  
  .custom-typo-stake-info{
    font-size: 12px;
    font-weight: bold;
}

.custom-font-table-data{
  font-size: 15px;
}

.custom-gmt{
  font-size: 15px;
  line-height: 1.4em;
  color:#252525;
}