&& .ant-menu-item::after {
  border-bottom: none !important;
}

/* make antd menu select text color */
&& .ant-menu-item-selected a {
  font-weight: 900 !important;
  color: black !important;
}

.custom-button{
  background-color: purple;
}

.custom-button:hover {
  transition: "all 0.2s ease, visibility 0s";
  background-color: #232323;
}

.custom-font-notification {
  color: "#20303c";
  flex-shrink: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  @media screen and (max-width: 420px) {
    font-size: 10px;
  }
}

.custom-dropdown-menu {
  width: 200px; /* Adjust this value to your desired width */
}
