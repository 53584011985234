.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.custom-table-cluster tr:nth-child(odd) {
  background-color: transparent;
}

.custom-table-cluster .ant-table-tbody > tr > td {
  border: none;
}

.custom-table-cluster .ant-table-thead tr th {
  background-color: #bdbdbd;
  font-size: 13px;
}

.custom-table-cluster
  .ant-table-container
  .ant-table-content
  table
  .ant-table-thead
  tr
  th:first-child {
  border-start-start-radius: 6px;
  border-end-start-radius: 6px;
}

.custom-table-cluster
  .ant-table-container
  .ant-table-content
  table
  .ant-table-thead
  tr
  th:last-child {
  border-start-end-radius: 6px;
  border-end-end-radius: 6px;
}
