.stake-setting-table tr:nth-child(odd) {
  background-color: white;
}

.stake-setting-table .ant-table-tbody::before {
  line-height: 0.5em;
  content: none;
  color: white;
  display: block;
}

.stake-setting-table table .ant-table-thead tr th {
  background-color: #bdbdbd;
}

.stake-setting-table .ant-table-container
  .ant-table-content
  table
  .ant-table-thead
  tr
  th:first-child {
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
}

.stake-setting-table .ant-table-container
  .ant-table-content
  table
  .ant-table-thead
  tr
  th:last-child {
  border-end-end-radius: 4px;
  border-start-end-radius: 4px;
}

.stake-setting-table .ant-table-container .ant-table-content table .ant-table-tbody tr td:first-child{
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
}

.stake-setting-table .ant-table-container .ant-table-content table .ant-table-tbody tr td:last-child{
  border-end-end-radius: 4px;
  border-start-end-radius: 4px;
}
