.custom-box-info{
    border: 1px solid #C7C7C7;
    padding: 5px 20px;
    color: #252525;
}

.custom-typo-title{
    font-size: 12px;
    font-weight: 700;
}

.custom-typo-subtitle{
    font-size: 15px;
    font-weight: bold;
}

.custom-info{
    font-size: 15px;
}

.custom-btn-general{
    color: #0011F7;
    font-size: 10px;
    margin-top: 4px;
}

.custom-btn-sign-up-code{
    color: #0011F7;
    font-size: 12px;
}