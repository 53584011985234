.custom-button-wallet-setting {
  width: 138px;
  height: 28px;
  font-size: 14px;
  background-color: #BDBDBD;
  @media screen and (max-width: 470px) {
    width: 100px;
    height: 40px;
  }
}

.custom-button-wallet-setting:hover{
  transition: all 0.2s ease, visibility 0s;
  background-color: #e3e0e0;
  color: grey;
}

.custom-staker-balance {
  @media screen and (max-width: 470px) {
    gap: 20px;
  }
}

.custom-cashflow {
  @media screen and (max-width: 570px) {
    gap: 140px;
  }

  @media screen and (max-width: 470px) {
    gap: 50px;
  }
}
