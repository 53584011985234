.user-info tr:nth-child(odd) {
  background-color: white;
}

.user-info tr:nth-child(even) {
  background-color: #ededed;
}

.user-info .ant-table-container .ant-table-content table .ant-table-thead tr th {
  background-color: #c7c7c7;
}

.user-info .ant-table-tbody::before {
  line-height: 0.5em;
  content: none;
  color: white;
  display: block;
}