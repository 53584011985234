.user-activities-pagination
  :where(.css-dev-only-do-not-override-1m62vyb).ant-pagination
  .ant-pagination-item {
  background-color: #ededed;
  border-color: #ededed;
}

.user-activities tr:nth-child(odd) {background-color: transparent;}

.custom-btn-layout-line2{
  border: 1px solid #38A01E;
  background-color: #38A01E;
  letter-spacing: 0.1em;
  color: white;
  padding: 1px 20px;
}

.custom-btn-layout-line2:hover{
  transition: all 0.2s ease, visibility 0s;
  color: black;
}

