
/* color of tick */
.styled-checkbox .ant-checkbox-inner::after {
  border-color: black;
}

.styled-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: white;
  border-color: black;
}

.styled-checkbox .ant-checkbox-checked::after {
  border-color: black;
}

.styled-checkbox .ant-checkbox .ant-checkbox-input{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}