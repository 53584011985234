.custom-footer-margin-y {
  padding: 40px 0;
  @media screen and (max-width: 600px) {
    padding: 15px 0;
  }
}

.custom-footer-typo {
  font-size: 16px;
  font-family: sans-serif;
  padding-bottom: 40px;
  @media screen and (max-width: 600px) {
    font-size: 13px;
    padding-bottom: 20px;
  }
}

.custom-footer-typo-2{
    letter-spacing: 0.02em;
    line-height: 1.5em;
    font-size: 15px;
    font-family: sans-serif;;
    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
}