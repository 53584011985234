/* Override the default height */
.ant-divider-vertical {
  height: auto;
  background-color: rgb(179, 179, 179);
}

.ant-divider-horizontal {
  background-color: rgb(179, 179, 179);
}

.info-input .ant-input::placeholder {
  color: rgb(179, 179, 179);
}

.info-modal .ant-modal .ant-modal-content {
  border-radius: 30px;
}

.custom-layout {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.custom-divider-vertical {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.custom-divider-horizontal {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.mobile-style-widget1 {
  @media screen and (max-width: 1400px) {
    flex-direction: column;
    gap: 20px;
  }
}
